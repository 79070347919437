<template>
	<v-sheet flat>
		<h4>Support Knowledge Base</h4>
		<p>This page contains a list of support queries received from users of the Digital Maturity Assessment, along with the solution provided to them by support staff. </p>
		<v-navigation-drawer permanent app right>
			<v-text-field class="ma-3" label="Search" v-model="search"></v-text-field>
			<v-divider></v-divider>
			<v-list dense>
				<v-list-item v-for="item in filteredItems" :key="item.id">
					<v-list-item-icon>
						<v-icon>mdi-chevron-right</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title @click="scr(item.id)">
							{{ item.question }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
        <v-card flat v-for="item in filteredItems" :key="item.id" :id="item.id">
            <v-card-title>{{item.question}}</v-card-title>
            <v-card-text>
                <div class="mb-3">

                {{item.answer}}
                </div>
                <template  v-for="tag in item.tags" >
                    <v-chip small color="primary" class="mr-2" :key="tag" v-if="categoryOptions[tag]">
                        {{categoryOptions[tag].name}}
                    </v-chip>
                </template>
            </v-card-text>
        </v-card>
	</v-sheet>
</template>

<script>
export default {
	name: "KnowledgeBase",
	data: () => {
		return {
			search: "",
		};
	},
	computed: {
		knowledgeBase() {
			return this.$store.state.knowledgeBase.data;
		},
        categoryOptions(){
            return this.$store.state.categoryOptions.data;
        },
		items() {
			const self = this;
			let items = Object.values(self.knowledgeBase);
			return items;
		},
        filteredItems(){
            const self = this;
			return self.items.filter((item) => {
					return item.question
						.toLowerCase()
						.includes(self.search.toLowerCase());
				});
        
        }
        
	},
	methods: {
		scr(id) {
			let elem = document.getElementById(id);
			let pos = elem.offsetTop;
			window.scrollTo(pos - 20);
		},
	},
	created() {
		this.$store.dispatch("knowledgeBase/openDBChannel");
		this.$store.dispatch("categoryOptions/openDBChannel");
	},
	destroyed() {
		this.$store.dispatch("knowledgeBase/closeDBChannel");
		this.$store.dispatch("categoryOptions/closeDBChannel");
	},
};
</script>
